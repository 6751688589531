import {
  Api, AuthenticationOptions, getDefaultClient, types as api, types,
} from '@mesa-labs/mesa-api';
import {
  AxiosError, AxiosInstance, AxiosResponse,
} from 'axios';
import { ThunkDispatch } from 'redux-thunk';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import jwtDecode from 'jwt-decode';
import { FacilitatorRedactedVendorApi } from '@mesa-labs/mesa-api/dist/apis/facilitatorRedactedVendor';
import { FacilitatorRedactedInvoiceApi } from '@mesa-labs/mesa-api/dist/apis/facilitatorRedactedInvoice';
import { FacilitatorRedactedExternalVendorApi } from '@mesa-labs/mesa-api/dist/apis/facilitatorRedactedExternalVendor';

import { updateAccessToken } from '../slices/auth';

export type TokenParam = {
  readonly accessToken: string;
};

type ApiCtor<T extends Api> = new (baseURL: string, options?: AuthenticationOptions, client?: AxiosInstance) => T;

const isTokenInvalidOrExpired = (token: string): boolean => {
  const payload = jwtDecode<string | { exp: number }>(token);
  if (!payload) {
    return true;
  }

  if (typeof payload === 'string' || !payload.exp) {
    return true;
  }

  return (Date.now() / 1000) > payload.exp;
};

export const useApi = <T extends Api>(baseURL: string, Ctor: ApiCtor<T>, dispatch: ThunkDispatch<any, any, any>, options?: AuthenticationOptions) => {
  const client = getDefaultClient(baseURL);

  client.interceptors.request.use((config) => {
    config.headers.set('mode', 'cors');
    config.headers.set('cache', 'no-cache');
    config.headers.set('credentials', 'same-origin');
    config.headers.set('Accept', 'application/json');
    config.headers.set('Content-Type', 'application/json');
    if (options) {
      config.headers.set('Authorization', `Bearer ${options.token}`);
    }
    return config;
  });

  client.interceptors.response.use(
    (response: AxiosResponse) => ({
      ...response,
      data: response.status === 404 ? undefined : response.data,
    }),
    async (err?: AxiosError) => {
      if (options?.token) {
        if (isTokenInvalidOrExpired(options.token)) {
          return dispatch(updateAccessToken(undefined));
        }
      }

      throw api.ApiError.fromAxiosError(err);
    },
  );

  // Don't pass options as they won't take effect due to the custom client
  return new Ctor(baseURL, undefined, client);
};

export const useFacilitatorRedactedVendorApi = (dispatch: ThunkDispatch<any, any, any>, options?: AuthenticationOptions) => useApi(CONFIG.api.vendorUrl, FacilitatorRedactedVendorApi, dispatch, options);
export const useFacilitatorRedactedInvoiceApi = (dispatch: ThunkDispatch<any, any, any>, options?: AuthenticationOptions) => useApi(CONFIG.api.invoiceUrl, FacilitatorRedactedInvoiceApi, dispatch, options);
export const useFacilitatorRedactedExternalVendorApi = (dispatch: ThunkDispatch<any, any, any>, options?: AuthenticationOptions) => useApi(CONFIG.api.externalVendorUrl, FacilitatorRedactedExternalVendorApi, dispatch, options);

export const facilitatorsApi = createApi({
  reducerPath: 'facilitatorsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: CONFIG.api.vendorUrl,
  }),
  tagTypes: ['Vendors', 'Vendor', 'VendorInvoices', 'Clients', 'Client', 'ClientVendors', 'ClientDivision', 'ExternalVendors', 'ExternalVendorFilters'],
  endpoints: (build) => ({
    authorizeFacilitator:
      build.query<api.AuthorizeFacilitatorResponse, api.AuthorizeFacilitatorRequest & { partnerId: api.Partners }>({
        async queryFn({ partnerId, ...request }, { dispatch }) {
          const vendorApi = useFacilitatorRedactedVendorApi(dispatch /* no token */);
          const data = await vendorApi.authorizeFacilitator(partnerId, request);
          return { data };
        },
      }),
    getAllVendorsAsFacilitator:
      build.query<api.IPagedResults<api.FacilitatorRedactedVendorResponse>, api.FacilitatorVendorFilterParams & { partnerId: api.Partners; } & TokenParam>({
        async queryFn({ accessToken, partnerId, ...params }, { dispatch }) {
          const vendorApi = useFacilitatorRedactedVendorApi(dispatch, { token: accessToken });
          const data = await vendorApi.getAllVendorsAsFacilitator(partnerId, params);
          return { data };
        },
        providesTags: ['Vendors'],
      }),
    getVendorAsFacilitator:
      build.query<api.FacilitatorRedactedVendorAggregateResponse | undefined, { partnerId: api.Partners; vendorId: types.UUID } & TokenParam>({
        async queryFn({ accessToken, partnerId, vendorId }, { dispatch }) {
          const vendorApi = useFacilitatorRedactedVendorApi(dispatch, { token: accessToken });
          const data = await vendorApi.getVendorAsFacilitator(partnerId, vendorId);
          return { data };
        },
        providesTags: (result, _error, { vendorId }) => (result
          ? [{ type: 'Vendor', vendorId, ...result }]
          : ['Vendor']),
      }),
    getAllInvoicesAsFacilitator:
      build.query<api.IPagedResults<api.FacilitatorRedactedInvoiceResponse>, api.FacilitatorInvoiceFilterParams & { partnerId: api.Partners; } & TokenParam>({
        async queryFn({ accessToken, partnerId, ...params }, { dispatch }) {
          const invoiceApi = useFacilitatorRedactedInvoiceApi(dispatch, { token: accessToken });
          const data = await invoiceApi.getAllInvoicesAsFacilitator(partnerId, params);
          return { data };
        },
        providesTags: (result, _error, { vendorId }) => (result
          ? [{ type: 'VendorInvoices', vendorId, ...result }]
          : ['VendorInvoices']),
      }),
    getAllExternalClientsAsFacilitator:
      build.query<api.IPagedResults<api.FacilitatorRedactedExternalClientResponse>, api.FacilitatorExternalClientFilterParams & { partnerId: api.Partners; } & TokenParam>({
        async queryFn({
          accessToken, partnerId, search, ...params
        }, { dispatch }) {
          const externalVendorApi = useFacilitatorRedactedExternalVendorApi(dispatch, { token: accessToken });
          const data = await externalVendorApi.getAllExternalClientsAsFacilitator(partnerId, { search, ...params });
          return { data };
        },
        providesTags: ['Clients'],
      }),
    getExternalClientAsFacilitator:
      build.query<api.FacilitatorRedactedExternalClientResponse | undefined, { partnerId: api.Partners; externalClientId: string; } & TokenParam>({
        async queryFn({ accessToken, partnerId, externalClientId }, { dispatch }) {
          const externalVendorApi = useFacilitatorRedactedExternalVendorApi(dispatch, { token: accessToken });
          const data = await externalVendorApi.getExternalClientAsFacilitator(partnerId, externalClientId);
          return { data };
        },
        providesTags: (result) => (result
          ? [{ type: 'Client', ...result }]
          : ['Client']),
      }),
    getExternalClientVendorsAsFacilitator:
      build.query<api.IPagedResults<api.FacilitatorRedactedExternalClientVendorResponse>, api.ExternalClientVendorFilterParams & { partnerId: api.Partners; externalClientId: string; } & TokenParam>({
        async queryFn({
          accessToken, partnerId, externalClientId, ...params
        }, { dispatch }) {
          const externalVendorApi = useFacilitatorRedactedExternalVendorApi(dispatch, { token: accessToken });
          const data = await externalVendorApi.getExternalClientVendorsAsFacilitator(partnerId, externalClientId, params);
          return { data };
        },
        providesTags: (result, _error, { partnerId, externalClientId }) => (result
          ? [{
            type: 'ClientVendors', partnerId, externalClientId, ...result,
          }]
          : ['ClientVendors']),
      }),
    getExternalClientDivisionAsFacilitator:
      build.query<api.FacilitatorRedactedExternalClientDivisionResponse, { partnerId: api.Partners; division: api.ExternalClientDivision } & TokenParam>({
        async queryFn({
          accessToken, partnerId, division,
        }, { dispatch }) {
          const externalVendorApi = useFacilitatorRedactedExternalVendorApi(dispatch, { token: accessToken });
          const data = await externalVendorApi.getExternalClientDivisionAsFacilitator(partnerId, division);
          return { data };
        },
        providesTags: (result, _error) => (result
          ? [{
            type: 'ClientDivision', ...result,
          }]
          : ['ClientDivision']),
      }),
    getAllExternalVendorsAsFacilitator:
      build.query<api.IPagedResults<api.FacilitatorRedactedExternalVendorResponse>, api.FacilitatorExternalVendorFilterParams & { partnerId: api.Partners } & TokenParam>({
        async queryFn({ accessToken, partnerId, ...params }, { dispatch }) {
          const externalVendorApi = useFacilitatorRedactedExternalVendorApi(dispatch, { token: accessToken });
          const data = await externalVendorApi.getAllExternalVendorsAsFacilitator(partnerId, params);
          return { data };
        },
        providesTags: ['ExternalVendors'],
      }),
    getExternalVendorAsFacilitator:
      build.query<api.FacilitatorRedactedExternalVendorResponse | undefined, { partnerId: api.Partners, externalVendorId: string } & TokenParam>({
        async queryFn({ accessToken, partnerId, externalVendorId }, { dispatch }) {
          const externalVendorApi = useFacilitatorRedactedExternalVendorApi(dispatch, { token: accessToken });
          const data = await externalVendorApi.getExternalVendorAsFacilitator(partnerId, externalVendorId);
          return { data };
        },
        providesTags: (result, _error) => (result
          ? [{
            type: 'ExternalVendors', ...result,
          }]
          : ['ExternalVendors']),
      }),
    getExternalVendorFiltersAsFacilitator:
      build.query<api.ExternalVendorFiltersResponse, { partnerId: api.Partners } & TokenParam>({
        async queryFn({ accessToken, partnerId }, { dispatch }) {
          const externalVendorApi = useFacilitatorRedactedExternalVendorApi(dispatch, { token: accessToken });
          const data = await externalVendorApi.getExternalVendorFiltersAsFacilitator(partnerId);
          return { data };
        },
        providesTags: ['ExternalVendorFilters'],
      }),
  }),
});

export const {
  useAuthorizeFacilitatorQuery,
  useGetAllVendorsAsFacilitatorQuery,
  useGetVendorAsFacilitatorQuery,
  useGetAllInvoicesAsFacilitatorQuery,
  useGetAllExternalClientsAsFacilitatorQuery,
  useGetExternalClientAsFacilitatorQuery,
  useGetExternalClientVendorsAsFacilitatorQuery,
  useGetExternalClientDivisionAsFacilitatorQuery,
  useGetAllExternalVendorsAsFacilitatorQuery,
  useGetExternalVendorAsFacilitatorQuery,
  useGetExternalVendorFiltersAsFacilitatorQuery,
} = facilitatorsApi;

export const asExportFilter = <T>(filter: T): T => ({
  ...filter,
  page: 1,
  limit: Number.MAX_SAFE_INTEGER,
});

export const exportAllVendorsAsFacilitator = async (partnerId: api.Partners, filterParams: api.FacilitatorVendorFilterParams, accessToken: string): Promise<BlobPart> => {
  const url = new URL(`${CONFIG.api.vendorUrl}/facilitators/partners/${partnerId}/vendors/csv/export`);
  Object.entries(filterParams || {}).forEach(([k, v]) => {
    if (v !== null && v !== undefined) {
      url.searchParams.append(k, v.toString());
    }
  });

  const response = await fetch(url.href, {
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return response.blob();
};

export const exportAllInvoicesAsFacilitator = async (partnerId: api.Partners, filterParams: api.FacilitatorInvoiceFilterParams, accessToken: string): Promise<BlobPart> => {
  const url = new URL(`${CONFIG.api.invoiceUrl}/facilitators/partners/${partnerId}/invoices/csv/export`);
  Object.entries(filterParams || {}).forEach(([k, v]) => {
    if (v !== null && v !== undefined) {
      url.searchParams.append(k, v.toString());
    }
  });

  const response = await fetch(url.href, {
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return response.blob();
};

export const exportAllExternalClientsAsFacilitator = async (partnerId: api.Partners, filterParams: api.FacilitatorExternalClientFilterParams, accessToken: string): Promise<BlobPart> => {
  const url = new URL(`${CONFIG.api.externalVendorUrl}/facilitators/partners/${partnerId}/external-clients/csv/export`);
  Object.entries(filterParams || {}).forEach(([k, v]) => {
    if (v !== null && v !== undefined) {
      url.searchParams.append(k, v.toString());
    }
  });

  const response = await fetch(url.href, {
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return response.blob();
};

export const exportAllExternalClientVendorsAsFacilitator = async (partnerId: api.Partners, externalClientId: string, filterParams: api.ExternalClientVendorFilterParams, accessToken: string): Promise<BlobPart> => {
  const url = new URL(`${CONFIG.api.externalVendorUrl}/facilitators/partners/${partnerId}/external-clients/${externalClientId}/external-vendors/csv/export`);
  Object.entries(filterParams || {}).forEach(([k, v]) => {
    if (v !== null && v !== undefined) {
      url.searchParams.append(k, v.toString());
    }
  });

  const response = await fetch(url.href, {
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return response.blob();
};

export const exportAllExternalVendorsAsFacilitator = async (partnerId: api.Partners, filterParams: api.FacilitatorExternalVendorFilterParams, accessToken: string): Promise<BlobPart> => {
  const url = new URL(`${CONFIG.api.externalVendorUrl}/facilitators/partners/${partnerId}/external-vendors/csv/export`);
  Object.entries(filterParams || {}).forEach(([k, v]) => {
    if (v !== null && v !== undefined) {
      url.searchParams.append(k, v.toString());
    }
  });

  const response = await fetch(url.href, {
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return response.blob();
};
